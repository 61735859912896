


















import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Component
export default class SpeakersListComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: null })
  private sessionCode!: string;

  @Prop({ required: false, default: () => [] })
  private data!: Data[];

  private get speakers(): string {
    if (this.data && this.data.length > 0 && this.data[0].speakers) {
      return this.data[0].speakers as string;
    }
    return '';
  }

  created(): void {
    if (this.payload && 'sessionCode' in this.payload && this.payload.sessionCode) {
      this.setDataConfig([{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: { uid: this.payload.sessionCode },
            type: GqlEntityFilterType.SESSION_FILTER,
          },
        }),
        operation: 'session',
        fragmentName: 'sessionSpeakersNameFragment',
        alias: this.storeName,
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing sessionCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }
}
